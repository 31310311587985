:root {
    --main-color: #777;
    --main-bg: #E9E9E7;

    --primary-bg: #000;
    --secondary-bg: #FAFAFA;
    --tertiary-bg: #111;
	
    --accent-color: #507B2D;
	--accent3-color: #BA3030;
	--accent4-color: #AC8359;
    
	--primary-color: #000;
    --secondary-color: #FAFAFA;
    --tertiary-color: #fff;
    --quarter-color: #DDDDDD;
    --fifth-color: #94B5AD;
    --sixth-color: #E9E7F0;
    
    --main-border-color: #222;
    --secondary-border-color: #333;
    --tertiary-border-color: #C1C1BF;
	--quarter-border-color: #444;

    --main-font-family: "Plus Jakarta Sans", sans-serif;
    --primary-font-family: "Alata", sans-serif;

    --button-bg: #507B2D;
    --button-color: #FAFAFA;
    --button-bg-hover: #38591D;
    --button-color-hover: #FAFAFA;
    --button2-bg: #FAFAFA;
    --button2-color: #02654D;
    --button2-bg-hover: #38591D;
    --button2-color-hover: #FAFAFA;
    // //--button3-bg: #111111;
    --button3-bg: transparent;
    --button3-color: #DDDDDD;
    // //--button3-bg-hover: #111111;
    --button3-bg-hover: transparent;
    --button3-color-hover: #FAFAFA;
}
