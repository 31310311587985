@import url('https://fonts.googleapis.com/css2?family=Alata&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?89z6j7');
    src: url('../fonts/icomoon.eot?89z6j7#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?89z6j7') format('truetype'),
        url('../fonts/icomoon.woff?89z6j7') format('woff'),
        url('../fonts/icomoon.svg?89z6j7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    // font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]>span,
[class*=" icon-"]>span {
    display: none;
}

.icon-brand-fb:before {
	content: "\41";
}

.icon-brand-x:before {
	content: "\42";
}

.icon-brand-ig:before {
	content: "\43";
}

.icon-brand-in:before {
	content: "\44";
}

.icon-brand-yt:before {
	content: "\45";
}

.icon-brand-wa:before {
	content: "\46";
}

.icon-share:before {
	content: "\47";
}

.icon-menu-burger:before {
	content: "\e900";
}

.icon-profile:before {
	content: "\e901";
}

.icon-menu-arrow:before {
	content: "\e902";
}

.icon-list-bullet:before {
	content: "\e903";
}

.icon-close:before {
	content: "\e904";
}

.icon-plus:before {
	content: "\e905";
}

.icon-minus:before {
	content: "\e906";
}

.icon-link:before {
	content: "\e907";
}

.icon-slider-arrow:before {
	content: "\e908";
}

.icon-pagination-arrow:before {
	content: "\e909";
}

.icon-pagination-arrows:before {
	content: "\e90a";
}

.icon-tick:before {
	content: "\e90b";
}

.icon-cross:before {
	content: "\e90c";
}

.icon-profile2:before {
	content: "\e90d";
}

.icon-phone:before {
	content: "\e90e";
}

.icon-email:before {
	content: "\e90f";
}

.icon-location:before {
	content: "\e910";
}

.icon-contactus:before {
	content: "\e911";
}

.icon-market-insights:before {
	content: "\e912";
}

.icon-market-coverage:before {
	content: "\e913";
}

.icon-expert:before {
	content: "\e914";
}

.icon-solutions:before {
	content: "\e915";
}

.icon-list-bullet2:before {
	content: "\e916";
}


.rtl-page .icon-link:before{
    display: inline-block;
    transform: scaleX(-1);
}